import { SidebarSimple } from "@phosphor-icons/react";
import { Button, Sheet, SheetClose, SheetContent } from "@reactive-resume/ui";
import { motion } from "framer-motion";
import { useState } from "react";
import { Outlet } from "react-router-dom";

import { ReloadingWrapper } from "../hooks/context";
import ButtonAppBar from "./_components/app-bar";
import Footer from "./_components/main-footer";
import { Sidebar } from "./_components/sidebar";

export const DashboardLayout = () => {
  const [open, setOpen] = useState(false);

  return (
    <ReloadingWrapper>
      <div className="flex min-h-screen flex-col">
        {" "}
        {/* Full height container */}
        <div className="sticky top-0 z-50 flex items-center justify-between p-4 pb-0 lg:hidden">
          <Sheet open={open} onOpenChange={setOpen}>
            <SheetContent showClose={false} side="left" className="focus-visible:outline-none">
              <SheetClose asChild className="absolute left-4 top-4">
                <Button size="icon" variant="ghost">
                  <SidebarSimple />
                </Button>
              </SheetClose>

              <Sidebar setOpen={setOpen} />
            </SheetContent>
          </Sheet>
        </div>
        <motion.div
          initial={{ x: -320 }}
          animate={{ x: 0 }}
          className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-[320px] lg:flex-col"
        >
          <div className="h-full rounded">
            <Sidebar />
          </div>
        </motion.div>
        <div className="sticky top-0 z-40">
          <ButtonAppBar setOpen={setOpen} />
        </div>
        {/* Flex-grow ensures the content fills the space */}
        <main className="mx-6 my-4 grow lg:mx-8 lg:pl-[240px]">
          <Outlet />
        </main>
        <div className="sticky top-0 z-40">
          <Footer />
        </div>
        {/* Footer at the bottom */}
      </div>
    </ReloadingWrapper>
  );
};
