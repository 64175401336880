import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { DataGrid, DataGridProps, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import React from "react";

// Define the props for MuiGridTable
type MuiGridTableProps = {
  rows: GridRowsProp;
  columns: GridColDef[];
  // Add any other DataGrid props you need here
} & Partial<DataGridProps>; // Use Partial to allow all DataGrid props

const StyleDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  borderRadius: 3,

  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(15),
    borderTopLeftRadius: theme.shape.borderRadius, // Rounded top-left corner
    borderTopRightRadius: theme.shape.borderRadius, // Rounded top-right corner
  },

  "& .MuiDataGrid-iconButtonContainer": {
    color: theme.palette.common.white,
  },

  "& .MuiDataGrid-sortIcon": {
    color: theme.palette.common.white,
  },

  "& .MuiDataGrid-columnHeaderCheckbox .MuiSvgIcon-root": {
    color: theme.palette.common.white,
  },

  "& .MuiDataGrid-cellCheckbox .MuiSvgIcon-root": {
    color: theme.palette.common.black,
  },

  "& .MuiDataGrid-menuIconButton": {
    color: theme.palette.common.white,
  },

  "& .MuiDataGrid-footer": {
    borderTop: "none",
  },

  "& .MuiDataGrid-root": {
    borderRadius: theme.shape.borderRadius,
  },
}));

const MuiGridTable: React.FC<MuiGridTableProps> = ({ ...props }) => {
  return (
    <Box sx={{ height: "100%", width: "100%", overflowX: "auto" }}>
      <StyleDataGrid {...props} disableDensitySelector hideFooter autoPageSize density="compact" />
    </Box>
  );
};

export default MuiGridTable;
