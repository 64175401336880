import { t } from "@lingui/macro";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import { Plus, PlusCircle } from "@phosphor-icons/react";
import {
  Award,
  Certification,
  CustomSection,
  Education,
  Experience,
  Interest,
  Language,
  Profile,
  Project,
  Publication,
  Reference,
  SectionKey,
  Skill,
  Volunteer,
} from "@reactive-resume/schema";
import { Button, ScrollArea, Separator } from "@reactive-resume/ui";
import { Tooltip } from "@reactive-resume/ui";
import { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { UserAvatar } from "@/client/components/user-avatar";
import { UserOptions } from "@/client/components/user-options";
import { useResumeStore } from "@/client/stores/resume";

import { BasicsSection } from "./sections/basics";
import { SectionBase } from "./sections/shared/section-base";
import { SectionIcon } from "./sections/shared/section-icon";
import { SummarySection } from "./sections/summary";

export const LeftSidebar = () => {
  // const containterRef = useRef<HTMLDivElement | null>(null);

  const addSection = useResumeStore((state) => state.addSection);
  const customSections = useResumeStore((state) => state.resume.data.sections.custom);
  const [selectedIcon, setSelectedIcon] = useState<SectionKey | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollIntoView = (selector: string) => {
    const section = containerRef.current?.querySelector(selector);
    section?.scrollIntoView({ behavior: "smooth" });
  };

  const handleIconClick = (id: SectionKey, selector: string) => {
    setSelectedIcon(id); // Set the selected icon
    scrollIntoView(selector); // Scroll to the section
  };
  return (
    <div className="flex bg-secondary-accent/30">
      <div className="hidden basis-12 flex-col items-center justify-between bg-secondary-accent/30 py-4 sm:flex">
        <Button asChild size="icon" variant="ghost" className="size-8 rounded-full">
          <Link to="/">
            <Tooltip side="right" content={t`Home`}>
              <CottageOutlinedIcon sx={{ fontSize: "17px", color: "#6C6C6E" }} />
            </Tooltip>
          </Link>
        </Button>

        <div className="flex flex-col items-center justify-center gap-y-2">
          <SectionIcon
            id="basics"
            name={t({
              message: "Basics",
              context:
                "The basics section of a resume consists of User's Picture, Full Name, Location etc.",
            })}
            onClick={() => {
              scrollIntoView("#basics");
            }}
          />
          <SectionIcon
            id="summary"
            selectedIcon={selectedIcon}
            onClick={() => {
              handleIconClick("summary", "#summary");
            }}
          />
          <SectionIcon
            id="profiles"
            selectedIcon={selectedIcon}
            onClick={() => {
              handleIconClick("profiles", "#profiles");
            }}
          />
          <SectionIcon
            id="experience"
            selectedIcon={selectedIcon}
            onClick={() => {
              handleIconClick("experience", "#experience");
            }}
          />
          <SectionIcon
            id="education"
            selectedIcon={selectedIcon}
            onClick={() => {
              handleIconClick("education", "#education");
            }}
          />
          <SectionIcon
            id="skills"
            selectedIcon={selectedIcon}
            onClick={() => {
              handleIconClick("skills", "#skills");
            }}
          />
          <SectionIcon
            id="languages"
            selectedIcon={selectedIcon}
            onClick={() => {
              handleIconClick("languages", "#languages");
            }}
          />
          <SectionIcon
            id="awards"
            selectedIcon={selectedIcon} // Pass the selected icon
            onClick={() => {
              handleIconClick("awards", "#awards");
            }}
          />
          <SectionIcon
            id="certifications"
            selectedIcon={selectedIcon}
            onClick={() => {
              handleIconClick("certifications", "#certifications");
            }}
          />
          <SectionIcon
            id="interests"
            selectedIcon={selectedIcon}
            onClick={() => {
              handleIconClick("interests", "#interests");
            }}
          />
          <SectionIcon
            id="projects"
            selectedIcon={selectedIcon}
            onClick={() => {
              handleIconClick("projects", "#projects");
            }}
          />
          <SectionIcon
            id="publications"
            selectedIcon={selectedIcon}
            onClick={() => {
              handleIconClick("publications", "#publications");
            }}
          />
          <SectionIcon
            id="volunteer"
            selectedIcon={selectedIcon}
            onClick={() => {
              handleIconClick("volunteer", "#volunteer");
            }}
          />
          <SectionIcon
            id="references"
            selectedIcon={selectedIcon}
            onClick={() => {
              handleIconClick("references", "#references");
            }}
          />

          <SectionIcon
            id="custom"
            variant="outline"
            name={t`Add a new section`}
            icon={<Plus size={14} />}
            selectedIcon={selectedIcon}
            onClick={() => {
              addSection();
              // eslint-disable-next-line lingui/no-unlocalized-strings
              scrollIntoView("& > section:last-of-type");
              handleIconClick("custom", "#custom");
            }}
          />
        </div>

        <UserOptions>
          <Button size="icon" variant="ghost" className="rounded-full">
            <UserAvatar size={28} />
          </Button>
        </UserOptions>
        {/* <Typography sx={{ fontSize: "12px" }}>{user?.name}</Typography> */}
      </div>

      <ScrollArea orientation="vertical" className="h-screen flex-1 pb-16 lg:pb-0">
        <div ref={containerRef} className="grid gap-y-6 p-6 @container/left">
          <BasicsSection />
          <Separator />
          <SummarySection />
          <Separator />
          <SectionBase<Profile>
            id="profiles"
            title={(item) => item.network}
            description={(item) => item.username}
          />
          <Separator />
          <SectionBase<Experience>
            id="experience"
            title={(item) => item.company}
            description={(item) => item.position}
          />
          <Separator />
          <SectionBase<Education>
            id="education"
            title={(item) => item.institution}
            description={(item) => item.area}
          />
          <Separator />
          <SectionBase<Skill>
            id="skills"
            title={(item) => item.name}
            description={(item) => {
              if (item.description) return item.description;
              if (item.keywords.length > 0) return `${item.keywords.length} keywords`;
            }}
          />
          <Separator />
          <SectionBase<Language>
            id="languages"
            title={(item) => item.name}
            description={(item) => item.description}
          />
          <Separator />
          <SectionBase<Award>
            id="awards"
            title={(item) => item.title}
            description={(item) => item.awarder}
          />
          <Separator />
          <SectionBase<Certification>
            id="certifications"
            title={(item) => item.name}
            description={(item) => item.issuer}
          />
          <Separator />
          <SectionBase<Interest>
            id="interests"
            title={(item) => item.name}
            description={(item) => {
              if (item.keywords.length > 0) return `${item.keywords.length} keywords`;
            }}
          />
          <Separator />
          <SectionBase<Project>
            id="projects"
            title={(item) => item.name}
            description={(item) => item.description}
          />
          <Separator />
          <SectionBase<Publication>
            id="publications"
            title={(item) => item.name}
            description={(item) => item.publisher}
          />
          <Separator />
          <SectionBase<Volunteer>
            id="volunteer"
            title={(item) => item.organization}
            description={(item) => item.position}
          />
          <Separator />
          <SectionBase<Reference>
            id="references"
            title={(item) => item.name}
            description={(item) => item.description}
          />

          {/* Custom Sections */}
          {Object.values(customSections).map((section) => (
            <Fragment key={section.id}>
              <Separator />

              <SectionBase<CustomSection>
                id={`custom.${section.id}`}
                title={(item) => item.name}
                description={(item) => item.description}
              />
            </Fragment>
          ))}

          <Separator />

          <Button size="lg" variant="outline" onClick={addSection}>
            <PlusCircle />
            <span className="ml-2">{t`Add a new section`}</span>
          </Button>
        </div>
      </ScrollArea>
    </div>
  );
};
