import { t } from "@lingui/macro"; // Import t from Lingui for translations
import { format, parse } from "date-fns";
export function DateFormat(dateString: string): string {
  const date = new Date(dateString);

  if (Number.isNaN(date.getTime())) {
    return dateString;
  }

  const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);
  const day = new Intl.DateTimeFormat("en-US", { day: "numeric" }).format(date);
  const year = new Intl.DateTimeFormat("en-US", { year: "numeric" }).format(date);

  return `${day}-${month}-${year}`;
}

export function AmountFormat(amount: number): string {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  }).format(amount);
}

export function CamelCase(str: string): string {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

type FormatType = "USA" | "INDIA";

export function formatPhoneNumber(phoneNumber: string, format: FormatType): string {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  if (format === "USA") {
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
    }
  } else {
    const match = cleaned.match(/^(\d{5})(\d{5})$/);
    if (match) {
      return `+91 ${match[1]} ${match[2]}`;
    }
  }

  return phoneNumber;
}

export function convertTo12HourFormat(time24: string) {
  const parsedTime = parse(time24, t`HH:mm:ss`, new Date());

  // Use a message descriptor with the translation key
  return format(parsedTime, t`hh:mm a`);
}
