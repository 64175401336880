import { t } from "@lingui/macro";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";

import { useAppContext } from "@/client/hooks/context";
import { useUser } from "@/client/services/user";

// Define the props for the alert
type AlertProps = {
  open: boolean;
  message: string;
  severity: "success" | "error" | "warning" | "info";
};

// Define the props type for the component
type ConfirmDialogModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  eventId: string;
  setAlert: (alert: AlertProps) => void;
};

const StatusConformPopup: React.FC<ConfirmDialogModalProps> = ({
  open,
  setOpen,
  eventId,
  setAlert,
}) => {
  // Initialize translation
  const { setReloading } = useAppContext();
  const { user } = useUser();
  const userId = user?.studentId;

  const handleSubmit = async (Value: string) => {
    const payload = {
      status: Value,
      event_id: eventId,
      student_id: userId,
    };
    try {
      await axios.post(`/api/placement/audience/status`, payload);
      setOpen(false);
      setReloading((prev) => prev + 1);
      setAlert({
        open: true,
        message: `${t`Value`} ${t`update_successfully`}`,
        severity: "success",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setAlert({
          open: true,
          message: error.response?.data?.message || t`An unknown error occurred`,
          severity: "error",
        });
      } else {
        setAlert({
          open: true,
          message: t`An unknown error occurred`,
          severity: "error",
        });
      }
      console.error(t`Error:`, error);
    }
  };

  return (
    <Dialog
      aria-labelledby="lease-addform-title"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "primary.dark",
          marginBottom: "30px",
          padding: "5px",
          color: "white",
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "20px",
          paddingRight: "20px",
          fontSize: "14px",
        }}
      >
        {t`Event Confirmation`}
        <CloseIcon
          sx={{
            position: "absolute",
            right: 2,
            marginTop: "1px",
            marginRight: "5px",
            color: "grey.400",
            "&:hover": {
              color: "#f94449",
            },
            fontSize: "20px",
          }}
          onClick={() => {
            setOpen(false);
          }}
        />
      </DialogTitle>

      <DialogContent
        sx={{
          pb: 1,
          mt: 1,
          width: "350px",
        }}
      >
        <DialogContentText id="alert-dialog-description" sx={{ pb: 0 }}>
          {t`Please confirm if you are attending the event`}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ pb: "10px" }}>
        <Button
          autoFocus
          id="delete-confirm-yes-btn"
          sx={{ textTransform: "none", p: "3px", fontSize: "13px", bgcolor: "#c6131b" }}
          variant="contained"
          onClick={() => handleSubmit("declined")}
        >
          {t`Decline`}
        </Button>
        <Button
          variant="contained"
          id="delete-confirm-no-btn"
          sx={{ textTransform: "none", border: "none", p: "2px" }}
          color="success"
          onClick={() => handleSubmit("accepted")}
        >
          {t`Accept`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StatusConformPopup;
