import { t } from "@lingui/macro"; // Import localization method (example using Lingui)

const Footer = () => {
  return (
    <div className="relative mb-[-19px] flex h-10 items-center justify-between bg-gray-200 px-4">
      <p className="flex-1 text-center text-sm text-gray-600">
        &copy; {new Date().getFullYear()} {t`JobPrep`}. {t`All rights reserved.`}
      </p>
      <p className="text-sm text-gray-600">
        {t`Powered by`} <span className="text-blue-500">{t`JobPrep`}</span>
      </p>
    </div>
  );
};

export default Footer;
