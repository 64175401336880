import { createZodDto } from "nestjs-zod/dto";
import { z } from "nestjs-zod/z";

export enum EventStudentStatus {
  ACCEPTED = "accepted",
  DECLINED = "declined",
}

export const audienceStatusSchema = z.object({
  status: z.nativeEnum(EventStudentStatus),
  event_id: z.string().min(1),
  student_id: z.string().min(1),
});

export class AudienceStatusDto extends createZodDto(audienceStatusSchema) {}
