import { styled } from "@mui/material";
import Chip from "@mui/material/Chip";

const StyleChip = styled(Chip)(({ theme }) => ({
  "& .MuiChip-root": {
    color: theme.palette.success,
  },
}));

export default function ChipLabel({ ...props }) {
  return (
    <StyleChip
      color={props.color}
      variant={props.variant}
      icon={props.startDecorator}
      label={props.label}
      sx={{ width: "100px", ...props.sx }}
      size={props.size}
      data-testid={props.testId}
    />
  );
}
