import { Inbox } from "@novu/react";

import { useUser } from "../user";

const applicationIdentifier = "rw40bFivPA0J";

export default function InApp() {
  const { user } = useUser();
  const userId: string = user?.studentId ?? ""; // Fallback to empty string

  return <Inbox applicationIdentifier={applicationIdentifier} subscriberId={userId} />;
}
