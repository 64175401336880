import { t } from "@lingui/macro";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Tooltip,
} from "@mui/material";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import { UserAvatar } from "@/client/components/user-avatar";
import InApp from "@/client/services/notification/in-app";
import { useUser } from "@/client/services/user";

import LogOut from "../log-out";

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ButtonAppBar: React.FC<Props> = ({ setOpen }) => {
  //   const router = useRouter();
  const isMobile = useMediaQuery({ query: t`(max-width: 760px)` });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [logout, setLogout] = React.useState(false);
  const openaccount = Boolean(anchorEl);
  const { user } = useUser();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleProfile = () => {
  //   console.log("Profile");

  // };

  const handleLogout = () => {
    setLogout(true);
  };

  const StyledDivider = styled(Divider)(() => ({
    backgroundColor: "#dbeafe",

    width: "100%",
  }));

  const StyledMenuItem = styled(MenuItem)(() => ({
    "&:hover": {
      backgroundColor: "#dbeafe",

      color: "black",
    },
    "&:focus": {
      backgroundColor: "#dbeafe",

      color: "black",
    },
    "&:active": {
      backgroundColor: "#dbeafe",

      color: "black",
    },
    marginLeft: "5px",
    marginRight: "5px",
    borderRadius: "7px",
  }));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <LogOut open={logout} setOpen={setLogout} />
      <AppBar
        position="static"
        elevation={0}
        sx={{
          height: 40,
          justifyContent: "center",
          backgroundColor: "white",
        }}
        className="border-b border-slate-400 py-1"
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "flex-end", // Align to the right
          }}
        >
          <Box>
            <InApp />
          </Box>

          <Box>
            {isMobile && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{
                  mr: 2,
                  color: "black",
                  borderRadius: "15px",
                }}
              >
                <MenuIcon
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              </IconButton>
            )}
          </Box>

          <Box mr={1}>
            <Tooltip arrow title={t`Account settings`}>
              <IconButton
                aria-controls={openaccount ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openaccount ? "true" : undefined}
                onClick={handleClick}
              >
                <Chip
                  id="profile_menu"
                  avatar={<UserAvatar size={24} className="mr-3" />}
                  label={user?.name}
                  sx={{
                    color: "white",
                    backgroundColor: "primary.main",
                    borderRadius: "15px",
                    "&:hover": {
                      backgroundColor: "#3f51b5",
                    },
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openaccount}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: t`visible`,
                // filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 0,
                mb: 0,
                "& .MuiAvatar-root": {
                  width: 30,
                  height: 30,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 7,
                  height: 7,
                  backgroundColor: "background.paper", // Use camelCase for bg color
                  // transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            onClose={handleClose}
            onClick={handleClose}
          >
            <StyledMenuItem>
              <Link to="/profile">
                <ListItemIcon>
                  <UserAvatar size={24} className="mr-3" />
                </ListItemIcon>
                <span style={{ marginTop: "-50px" }}>{t`My Profile`}</span>
              </Link>
            </StyledMenuItem>

            <StyledDivider />

            <StyledMenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout id="logout_menu" fontSize="small" />
              </ListItemIcon>
              {t`Logout`}
            </StyledMenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default ButtonAppBar;
