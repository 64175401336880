import { t } from "@lingui/macro";
import { AlertColor, Box, Button, Container, Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";

import Alerts from "@/client/components/alerts";
import { AmountFormat, DateFormat } from "@/client/components/custom/common-format";
import ChipLabel from "@/client/components/custom/custom-chip";
import MuiGridTable from "@/client/components/custom/grid-table";
import { useAppContext } from "@/client/hooks/context";
import { useUser } from "@/client/services/user";

import PlacementCofirm from "./placement-confirm";

// Defining types for better TypeScript support
type Job = {
  id: string;
  title: string;
  salary: number;
  status: string;
};

type Placement = {
  title: string;
  jobs: Job[];
  start_date: string;
  end_date: string;
  company?: { name: string };
  type: string;
};

const PlacementPage = () => {
  const [data, setData] = useState<Placement[]>([]);
  console.log("dataValue", data);

  const { user } = useUser();
  const userId = user?.studentId;
  const { loading, setLoading, reloading } = useAppContext();
  const [open, setOpen] = useState(false);

  const [jobId, setJobId] = useState<string>("");
  const [alert, setAlert] = useState<{ open: boolean; message: string; severity: AlertColor }>({
    open: false,
    message: "",
    severity: "info",
  });

  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: "",
      severity: "info",
    });
  };

  const handleEventStatus = (status: string) => {
    switch (status) {
      case "invited": {
        return (
          <ChipLabel
            color="warning"
            label={t`Invited`}
            sx={{ fontSize: "12px", p: "0px", height: "28px" }}
          />
        );
      }

      // case "ready": {
      //   return (
      //     <ChipLabel
      //       color="warning"
      //       label={t`Ready`}
      //       sx={{ fontSize: "12px", p: "0px", height: "28px" }}
      //     />
      //   );
      // }
      case "accepted": {
        return (
          <ChipLabel
            color="success"
            label={t`Accepted`}
            sx={{ fontSize: "12px", p: "0px", height: "28px" }}
          />
        );
      }
      case "declined": {
        return (
          <ChipLabel
            color="error"
            label={t`Declined`}
            sx={{ fontSize: "12px", p: "0px", height: "28px" }}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      const timer = setTimeout(async () => {
        try {
          const res = await axios.get(`/api/placement/${userId}`);
          console.log("RESULT", res);
          setData(res.data.result);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    };

    fetchData();
  }, [reloading]);

  // Flattening the structure to map the jobs for each placement
  const rows = data.flatMap((item: Placement) => {
    return item.jobs.map((job: Job) => ({
      id: job.id,
      title: item.title,
      jobTitle: job.title,
      startDate: item.start_date,
      endDate: item.end_date,
      company: item.company?.name,
      type: item.type,
      status: job.status,
      salary: job.salary,
    }));
  });

  const handleStatusOpen = (id: string) => {
    setOpen(true);
    setJobId(id);
  };
  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: t`Title`, // Localized string
      width: 140,
      headerAlign: "left",
      align: "left",
      hideable: false,
    },
    {
      field: "jobTitle",
      headerName: t`Job Title`, // Localized string
      width: 130,
      headerAlign: "left",
      align: "left",
      hideable: false,
    },
    {
      field: "startDate",
      headerName: t`Start Date`, // Localized string
      width: 115,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => DateFormat(params.row.startDate),
      sortable: false,
      disableColumnMenu: true,
      hideable: false,
      filterable: false,
    },
    {
      field: "endDate",
      headerName: t`End Date`, // Localized string
      width: 115,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => DateFormat(params.row.endDate),
      sortable: false,
      disableColumnMenu: true,
      hideable: false,
      filterable: false,
    },
    {
      field: "company",
      headerName: t`Company`, // Localized string
      width: 130,
      headerAlign: "left",
      align: "left",
      hideable: false,
    },
    {
      field: "salary",
      headerName: t`Salary`, // Localized string
      width: 100,
      headerAlign: "left",
      align: "left",
      hideable: false,
      renderCell: (params) => AmountFormat(params.row.salary),
    },
    {
      field: "type",
      headerName: t`Type`, // Localized string
      width: 60,
      headerAlign: "center",
      align: "center",
      hideable: false,
      renderCell: (params) => {
        const typeValue: string = params.row.type.toUpperCase();
        const formattedTypeValue =
          typeValue.charAt(0).toUpperCase() + typeValue.slice(1).toLowerCase();
        return (
          <Tooltip arrow title={formattedTypeValue}>
            <Typography sx={{ fontWeight: "bold", fontSize: "17px", mt: "5px" }}>
              {typeValue.charAt(0).toUpperCase()}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: "status",
      headerName: t`Status`,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => handleEventStatus(params.row.status),
      hideable: false,
    },
    {
      field: "action",
      headerName: t`Action`,
      width: 100,
      headerAlign: "center",
      align: "right",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Stack direction="row">
          <Button
            variant="contained"
            color="success"
            sx={{ textTransform: "none", fontSize: "10px" }}
            disabled={params.row.status === "accepted" || params.row.status === "declined"}
            onClick={() => {
              handleStatusOpen(params.row.id);
            }}
          >
            {t`Confirmation`}
          </Button>
        </Stack>
      ),
    },
  ];

  return (
    <Box>
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={handleCloseAlert}
      />
      <PlacementCofirm setOpen={setOpen} open={open} jobId={jobId} setAlert={setAlert} />
      <Container>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: "bold",
              fontSize: "1.2rem",
              pb: 1,
            }}
          >
            {t`Placement`} {/* Localized string */}
          </Typography>
        </Box>

        <Box sx={{ height: "75vh", mt: 5 }}>
          <MuiGridTable
            loading={loading} // Shorthand prop placed before others
            rows={rows}
            columns={columns}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
              },
            }}
            disableColumnSelector={true}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default PlacementPage;
