// import { Roboto } from 'next/font/google';
import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";
// const roboto = Roboto({
//   weight: ['300', '400', '500', '700'],
//   subsets: ['latin'],
//   display: 'swap',
// });

const theme = createTheme({
  // typography: {
  //   fontFamily: roboto.style.fontFamily,
  // },
  mixins: {
    MuiDataGrid: {
      // Pinned columns sections
      pinnedBackground: "#102d4d",
      // Headers, and top & bottom fixed rows
      containerBackground: "#102d4d",
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#102D4D",
      light: "#184872",
      dark: "#102d4d",
    },
    success: {
      main: "#15a81a",
      light: "#24b724",
      dark: "#009608",
    },
  },
});

export default theme;
