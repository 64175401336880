import { t } from "@lingui/macro";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import { Button, KeyboardShortcut } from "@reactive-resume/ui";
import { cn } from "@reactive-resume/utils";
import { motion } from "framer-motion";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useKeyboardShortcut from "use-keyboard-shortcut";

type Props = {
  className?: string;
};

const ActiveIndicator = ({ className }: Props) => (
  <motion.div
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    className={cn(
      "size-1.5 animate-pulse rounded-full bg-info shadow-[0_0_12px] shadow-info",
      className,
    )}
  />
);

type SidebarItem = {
  path: string;
  name: string;
  shortcut?: string;
  icon: React.ReactNode;
};

type SidebarItemProps = SidebarItem & {
  onClick?: () => void;
};

const SidebarItem = ({ path, name, shortcut, icon, onClick }: SidebarItemProps) => {
  const isActive = useLocation().pathname === path;

  return (
    <Button
      asChild
      size="lg"
      // variant="ghost"
      className={cn(
        "h-auto justify-start px-4 py-3 text-slate-50 hover:bg-primary-accent",
        isActive && "pointer-events-none bg-primary-accent text-slate-50",
      )}
      onClick={onClick}
    >
      <Link to={path}>
        <div className="mr-3 text-[13px]">{icon}</div>
        <span>{name}</span>
        {!isActive && <KeyboardShortcut className="ml-auto">{shortcut}</KeyboardShortcut>}
        {isActive && <ActiveIndicator className="ml-auto" />}
      </Link>
    </Button>
  );
};

type SidebarProps = {
  setOpen?: (open: boolean) => void;
};

export const Sidebar = ({ setOpen }: SidebarProps) => {
  const navigate = useNavigate();

  useKeyboardShortcut(["shift", "r"], () => {
    navigate("/resumes");
    setOpen?.(false);
  });

  useKeyboardShortcut(["shift", "s"], () => {
    navigate("/profile");
    setOpen?.(false);
  });

  const sidebarItems: SidebarItem[] = [
    {
      path: "/",
      name: t`Home`,
      shortcut: "⇧R",
      icon: <CottageOutlinedIcon />,
    },
    {
      path: "/assessments",
      name: t`Assessments`,
      shortcut: "⇧R",
      icon: <AnalyticsOutlinedIcon />,
    },
    {
      path: "/placement",
      name: t`Placement`,
      shortcut: "⇧R",
      icon: <PersonPinOutlinedIcon />,
    },
    {
      path: "/events",
      name: t`Events`,
      shortcut: "⇧R",
      icon: <EventNoteOutlinedIcon />,
    },
    {
      path: "/resumes",
      name: t`Resumes`,
      shortcut: "⇧R",
      icon: <ArticleOutlinedIcon />,
    },
    // {
    //   path: "/settings",
    //   name: t`Settings`,
    //   shortcut: "⇧S",
    //   icon: <FadersHorizontal />,
    // },
  ];

  return (
    <>
      <div className="mx-auto flex h-[40px] w-[250px] items-center gap-2.5 whitespace-pre border-b border-slate-400 bg-white px-3.5 py-0.5 font-medium dark:bg-slate-900 dark:text-slate-100">
        <h1 className="flex text-[20px] font-bold text-primary">
          <img src="/icon/logo-jobprep.png" alt="logo" className="mt-[-4px] size-6" />
          {t`obPrep`}
        </h1>
      </div>
      <div className="flex h-full w-[240px] flex-col bg-primary p-3">
        <div className="grid gap-y-2">
          {sidebarItems.map((item) => (
            <SidebarItem {...item} key={item.path} onClick={() => setOpen?.(false)} />
          ))}
        </div>

        {/* <div className="mt-[-10px]">
          <UserOptions>
            <Button size="lg" variant="ghost" className="w-full justify-start px-3">
              <UserAvatar size={24} className="mr-3" />
              <span>{user?.name}</span>
            </Button>
          </UserOptions>
        </div> */}

        {/* <Copyright className="ml-2" /> */}
      </div>
    </>
  );
};
