import { t } from "@lingui/macro";
import { createContext, ReactNode, useContext, useState } from "react";
type ReloadingContextType = {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  reloading: number;
  setReloading: React.Dispatch<React.SetStateAction<number>>;
};

const ReloadingContext = createContext<ReloadingContextType | undefined>(undefined);

type ReloadingWrapperProps = {
  children: ReactNode;
};

export function ReloadingWrapper({ children }: ReloadingWrapperProps) {
  const [loading, setLoading] = useState(false);
  const [reloading, setReloading] = useState(0);

  return (
    <ReloadingContext.Provider value={{ loading, setLoading, reloading, setReloading }}>
      {children}
    </ReloadingContext.Provider>
  );
}

export function useAppContext() {
  const context = useContext(ReloadingContext);
  if (context === undefined) {
    throw new Error(t`useAppContext must be used within a ReloadingWrapper`);
  }
  return context;
}
