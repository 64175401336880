import Alert, { AlertColor } from "@mui/material/Alert";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import React from "react";

type AlertsProps = {
  anchorOrigin?: SnackbarOrigin;
  open: boolean;
  onClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
  severity: AlertColor; // Updated to AlertColor
  message: string;
};

const Alerts: React.FC<AlertsProps> = ({ anchorOrigin, open, onClose, severity, message }) => {
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Snackbar
        key={severity}
        anchorOrigin={anchorOrigin || { vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={2000}
        onClose={onClose}
      >
        <Alert
          id="alert_message"
          variant="filled"
          severity={severity}
          sx={{ width: "100%" }}
          onClose={onClose}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default Alerts;
