import { t } from "@lingui/macro"; // Import localization method
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

import { useLogout } from "../services/auth";

// Define the props type
type ConfirmDialogModalProps = {
  open: boolean; // This should be used to control the dialog visibility
  setOpen: (open: boolean) => void; // Function to set the open state
};

const LogOut: React.FC<ConfirmDialogModalProps> = ({ open, setOpen }) => {
  const { logout } = useLogout();

  return (
    <Dialog
      open={open}
      aria-labelledby="lease-addform-title"
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "primary.dark",
          marginBottom: "30px",
          padding: "5px",
          color: "white",
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "20px",
          paddingRight: "20px",
          fontSize: "14px",
        }}
      >
        {t`Logout Confirmation`}
        <CloseIcon
          sx={{
            position: "absolute",
            right: 2,
            marginTop: "1px",
            marginRight: "5px",
            color: "grey.400",
            "&:hover": {
              color: "#f94449",
            },
            fontSize: "20px",
          }}
          onClick={() => {
            setOpen(false);
          }}
        />
      </DialogTitle>

      <DialogContent
        sx={{
          pb: 1,
          mt: 1,
          width: "350px",
        }}
      >
        <DialogContentText id="alert-dialog-description" sx={{ pb: 2 }}>
          {t`Are you sure to logout?`}
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ pb: "10px" }}>
        <Button
          variant="outlined"
          id="delete-confirm-no-btn"
          sx={{ textTransform: "none", border: "none", p: "2px" }}
          onClick={() => {
            setOpen(false);
          }}
        >
          {t`Cancel`}
        </Button>
        <Button
          autoFocus
          id="delete-confirm-yes-btn"
          sx={{ textTransform: "none", p: "3px", fontSize: "13px" }}
          variant="contained"
          color="primary"
          onClick={async () => {
            await logout();
          }}
        >
          {t`Confirm`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogOut;
