import { t } from "@lingui/macro"; // Import localization method
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { AlertColor } from "@mui/material/Alert";
import { GridColDef, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";

import Alerts from "@/client/components/alerts";
import { convertTo12HourFormat, DateFormat } from "@/client/components/custom/common-format";
import ChipLabel from "@/client/components/custom/custom-chip";
import MuiGridTable from "@/client/components/custom/grid-table";
import { useAppContext } from "@/client/hooks/context";
import { useUser } from "@/client/services/user";

import StatusConformPopup from "./status-confirm";

const Events = () => {
  const [data, setData] = useState<
    {
      id: string;
      event_name: string;
      category: { name: string };
      start_date: string;
      start_time: string;
      end_date: string;
      end_time: string;
      audience_status: string;
    }[]
  >([]);
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  const { loading, setLoading, reloading } = useAppContext();
  const [eventId, setEventId] = useState<string>("");
  const userId = user?.studentId;

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       const timer = setTimeout(async () => {
  //         try {
  //           const res = await axios.get(`/api/placement/events/${userId}`);
  //           setData(res.data.result);
  //         } catch (error) {
  //           console.error(error);
  //         } finally {
  //           setLoading(false);
  //         }
  //       }, 1000);
  //       return () => {
  //         clearTimeout(timer);
  //       };
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  // }, [reloading, setLoading, userId]);

  useEffect(() => {
    const fetchData = () => {
      // no async here
      setLoading(true);
      try {
        const timer = setTimeout(async () => {
          // async inside setTimeout
          try {
            const res = await axios.get(`/api/placement/events/${userId}`);
            setData(res.data.result);
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        }, 1000);
        return () => {
          clearTimeout(timer);
        };
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [reloading]);

  const handleEventStatus = (status: string) => {
    switch (status) {
      case "invited": {
        return (
          <ChipLabel
            color="warning"
            label={t`Invited`}
            sx={{ fontSize: "12px", p: "0px", height: "28px" }}
          />
        );
      }
      case "accepted": {
        return (
          <ChipLabel
            color="success"
            label={t`Accepted`}
            sx={{ fontSize: "12px", p: "0px", height: "28px" }}
          />
        );
      }
      case "declined": {
        return (
          <ChipLabel
            color="error"
            label={t`Declined`}
            sx={{ fontSize: "12px", p: "0px", height: "28px" }}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const handleStatusOpen = (id: string) => {
    setOpen(true);
    setEventId(id);
  };

  const rows = data.map((item) => ({
    id: item.id,
    title: item.event_name,
    category: item.category.name,
    startDate: item.start_date,
    startTime: item.start_time,
    status: item.audience_status,
    endDate: item.end_date,
    endTime: item.end_time,
  }));

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: t`Title`,
      width: 180,
      headerAlign: "left",
      align: "left",
      hideable: false,
    },
    {
      field: "category",
      headerName: t`Category`,
      width: 160,
      headerAlign: "left",
      align: "left",
      hideable: false,
    },
    {
      field: "startDate",
      headerName: t`Start Date`,
      width: 120,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => DateFormat(params.row.startDate),
      sortable: false,
      disableColumnMenu: true,
      hideable: false,
      filterable: false,
    },
    {
      field: "startTime",
      headerName: t`Start Time`,
      width: 100,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => convertTo12HourFormat(params.row.startTime),
      sortable: false,
      disableColumnMenu: true,
      hideable: false,
      filterable: false,
    },
    {
      field: "endDate",
      headerName: t`End Date`,
      width: 120,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => DateFormat(params.row.endDate),
      sortable: false,
      disableColumnMenu: true,
      hideable: false,
      filterable: false,
    },
    {
      field: "endTime",
      headerName: t`End Time`,
      width: 100,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => convertTo12HourFormat(params.row.endTime),
      sortable: false,
      disableColumnMenu: true,
      hideable: false,
      filterable: false,
    },
    {
      field: "status",
      headerName: t`Status`,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => handleEventStatus(params.row.status),
      hideable: false,
    },
    {
      field: "action",
      headerName: t`Action`,
      width: 100,
      headerAlign: "center",
      align: "right",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Stack direction="row">
          <Button
            variant="contained"
            color="success"
            sx={{ textTransform: "none", fontSize: "10px" }}
            disabled={params.row.status === "accepted" || params.row.status === "declined"}
            onClick={() => {
              handleStatusOpen(params.row.id);
            }}
          >
            {t`Confirmation`}
          </Button>
        </Stack>
      ),
    },
  ];

  const [alert, setAlert] = useState<{ open: boolean; message: string; severity: AlertColor }>({
    open: false,
    message: "",
    severity: "info",
  });

  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: "",
      severity: "info",
    });
  };

  return (
    <Box>
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={handleCloseAlert}
      />
      <StatusConformPopup setOpen={setOpen} open={open} eventId={eventId} setAlert={setAlert} />
      <Container>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: "bold", fontSize: "1.2rem", pb: 1 }}
          >
            {t`Events`}
          </Typography>
        </Box>

        <Box sx={{ height: "75vh", mt: 5 }}>
          <MuiGridTable
            disableColumnSelector
            rows={rows}
            columns={columns}
            loading={loading}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
              },
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default Events;
